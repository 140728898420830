import { EmptyImg, Cut, imgExpandData2Str } from '@shein-aidc/basis-resource'

let innerCut = null

const EmptyNewImg = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAA1JREFUGFdj+PTp038ACYYD1sVKshAAAAAASUVORK5CYII='

/** 图片转化及裁切 */
function transfromAndCutImg({ deviceData, isSupportWebp, isSupprotCut, imgUrl, designWidth, sceneMap, exp, scene = '' }) {
  const { width } = deviceData2Obj(deviceData)
  return new Cut({
    width,
    isMobile: true,
    isSupportWebp,
    isSupprotCut,
    sceneMap,
    scene,
  }).transfromImg({
    imgUrl,
    width: designWidth,
    exp,
  })
}

function deviceData2Obj(deviceDataStr) {
  if (!deviceDataStr) {
    return {}
  }

  const obj = {}
  decodeURIComponent(deviceDataStr).split('&').forEach(item => {
    const [key, value] = item.split('=')
    if (key && value) {
      obj[key] = value
    }
  })

  const width = Number.isNaN(Number(obj.width)) ? undefined : Number(obj.width)
  const isIOS = obj.ios === '1'

  return {
    width,
    isIOS,
  }
}

export {
  EmptyImg,
  EmptyNewImg,
  imgExpandData2Str,
  transfromAndCutImg,
}
