
import { apolloSuggestedSaleConfig } from '../constant.js'

export const getCardConfig = (config, key, source) => {
  if (config && config.hasOwnProperty(key)) {
    return config[key]
  } else {
    // list：默认全关，只有为true的配置项才会取值   other: 默认全开，只有为false的配置项才会忽略
    if (['list', 'other'].includes(source)) {
      return source === 'other'
    } else {
      return source
    }
    
  }
}

export const isFrPlSuggestedSalePrice = (type) => {
  const FrPlValue = [apolloSuggestedSaleConfig.NORMAL, apolloSuggestedSaleConfig.SPECIAL]
  return FrPlValue.includes(type)
}

export const isDeSuggestedSalePrice = (type) => {
  return type === apolloSuggestedSaleConfig.SPECIALDE
}
export const isNomalSuggestedSalePrice = (type) => {
  return type === apolloSuggestedSaleConfig.NORMAL
}
export const isSpecialSuggestedSalePrice = (type) => {
  return type === apolloSuggestedSaleConfig.SPECIAL
}

const getIsInversion = (data) => {
  const isInversion = data?.isInversion || data?.is_inversion

  return Number(isInversion || 0)
}

/**
 * 获取到手价以及标签文字 （仅满足门槛时，Estimated文字标签）
 * @param {*} goodsInfo 预处理完商品数据
 * @param {*} language 商品项多语言
 * @returns 
 */
export const getEstimatedPriceAndLabel = (goodsInfo, language, options) => {
  const { config, source } = options ?? {}

  if (!getCardConfig(config, 'showEstimatedPriceOnSale', source)) return {}

  const bffGoodsInfo = goodsInfo.bffGoodsInfo

  // 新客到手价[pageId=1340027622]
  let isShowNewUserOnlyPriceLabel = false
  const showNewuseronlyPriceLabel = getCardConfig(config, 'showNewuseronlyPriceLabel', source)
  
  if (bffGoodsInfo) {
    isShowNewUserOnlyPriceLabel = showNewuseronlyPriceLabel && Number(goodsInfo?.isNewCoupon) === 1
  } else {
    const isNewUserEstimatedPrice = [23, 56, 57, 65].includes(goodsInfo.estimatedPriceInfo?.estimatedCouponTypeId) 
    const isNewCouponProduct = goodsInfo?.isNewCoupon
    isShowNewUserOnlyPriceLabel = showNewuseronlyPriceLabel && isNewUserEstimatedPrice && isNewCouponProduct // 当前为新客到手价 & 根据ab展示新客到手价说明
  }

  const { satisfied, estimatedPrice, estimatedPriceType } = goodsInfo.estimatedPriceInfo ?? {}

  // 是付费会员到手价
  const isSheinClubEstimatedPrice = estimatedPriceType === 2
  // 是S3会员到手价
  const isExclusiveEstimatedPrice = estimatedPriceType === 4

  // 显示底部标签文字
  const noNeedAnyEstimatedPriceTag = getCardConfig(config, 'noNeedAnyEstimatedPriceTag', false)
  
  let isShow = satisfied === 1 && estimatedPrice?.amountWithSymbol
  // 判断传入的配置
  if (isShow && isSheinClubEstimatedPrice && !getCardConfig(config, 'showSheinClubPriceOnSale', source)) {
    isShow = false
  } else if (isShow && isExclusiveEstimatedPrice && !getCardConfig(config, 'showS3VipPriceOnSale', false)) {
    isShow = false
  }

  let labelText = ''
  let color = ''
  if (isSheinClubEstimatedPrice) {
    labelText = `Est. SHEIN CLUB ${ language.SHEIN_KEY_PWA_26354 || 'price' }`
    color = 'brown'
  } else if (isExclusiveEstimatedPrice) {
    labelText = language?.SHEIN_KEY_PWA_26364 || 'Estimated'
    color = 'darkGold'
  } else if (isShowNewUserOnlyPriceLabel) {
    labelText = `Est.${language.SHEIN_KEY_PWA_26353}`
    color = 'origin'
  } else {
    labelText = language?.SHEIN_KEY_PWA_26364 || 'Estimated'
    color = 'origin'
  }

  return {
    estimatedPriceType,
    priceInfo: isShow ? estimatedPrice : {},
    color: color,
    labelText: !noNeedAnyEstimatedPriceTag && isShow ? labelText : ''
  }
}

/**
 * 获取付费会员价以及标签文字
 * @param {*} goodsInfo 预处理完商品数据
 * @param {*} language 商品项多语言
 * @returns 
 */
export const getSheinClubPriceAndLabel = (goodsInfo, language, options) => {
  const { config, source } = options ?? {}
  const { sheinClubPromotionInfo } = goodsInfo ?? {}

  if (!sheinClubPromotionInfo || !getCardConfig(config, 'showSheinClubPriceOnSale', source)) return {}

  const price = sheinClubPromotionInfo?.price ?? {}
  const priceInfo = price?.amountWithSymbol ? price : {}
  
  // 合规价
  const isInversion = getIsInversion(sheinClubPromotionInfo)

  let labelText = ''
  if (price?.amountWithSymbol && getCardConfig(config, 'showSheinClubNewLabel', source)) {
    labelText = `Est. SHEIN CLUB ${ language?.SHEIN_KEY_PWA_26354 || 'price' }`
  }

  return {
    priceInfo,
    color: 'brown',
    labelText,
    isInversion: isInversion,
    suggestedSalePrice: sheinClubPromotionInfo.suggestedSalePrice ?? {}
  }
}

/**
 * 获取S3会员价以及标签文字
 * @param {*} goodsInfo 预处理完商品数据
 * @param {*} language 商品项多语言
 * @returns 
 */
export const getExclusivePriceAndLabel = (goodsInfo, language, options) => {
  const { config } = options ?? {}
  const { exclusivePromotionInfo } = goodsInfo ?? {}

  if (!exclusivePromotionInfo || !getCardConfig(config, 'showS3VipPriceOnSale', false)) return {}

  const price = exclusivePromotionInfo?.price ?? {}
  const priceInfo = price?.amountWithSymbol ? price : {}
  
  // 合规价
  const isInversion = getIsInversion(exclusivePromotionInfo)

  return {
    priceInfo,
    color: 'darkGold',
    labelText: '',
    isInversion,
    suggestedSalePrice: exclusivePromotionInfo.suggestedSalePrice ?? {}
  }
}


/**
 * 获取新型闪购价以及标签文字
 * @param {*} goodsInfo 预处理完商品数据
 * @param {*} language 商品项多语言 
 * @returns 
 */
export const getNewFlashPriceAndLabel = (goodsInfo, language, options) => {
  const { config, source } = options ?? {}

  // 是否接了预处理services/product_list/products/goodsPretreatInfo.js
  const pretreatInfo = goodsInfo.pretreatInfo
  const newFlashPromotion = pretreatInfo ? pretreatInfo.newFlashPromotion : goodsInfo.newFlashPromotion

  if (!newFlashPromotion || !getCardConfig(config, 'showNewFlashPriceOnSale', source)) return {}
  
  const priceInfo = newFlashPromotion?.price?.amountWithSymbol ? newFlashPromotion.price : {}

  const isInversion = getIsInversion(newFlashPromotion)

  let labelText = ''
  if (getCardConfig(config, 'showNewFlashNewLabel', source)) {
    switch(newFlashPromotion.flash_type) {
      case 2:
        labelText = `Est. ${language?.SHEIN_KEY_PWA_26353 || 'New user price'}`
        break
      case 3:
        labelText = `Est. ${language?.SHEIN_KEY_PWA_26352 || 'Exclusive'}`
        break
    }
  }

  if (newFlashPromotion.flashTypeText) {
    priceInfo.flashTypeText = newFlashPromotion.flashTypeText || ''
    labelText = ''
  }

  return {
    priceInfo,
    color: 'origin',
    labelText,
    flashTypeText: newFlashPromotion.flashTypeText || '',
    isInversion,
    suggestedSalePrice: newFlashPromotion?.suggested_sale_price ?? {}
  }
}

/**
 * 获取分人群专区闪购价价以及标签文字
 * @param {*} goodsInfo 预处理完商品数据
 * @param {*} language 商品项多语言
 * @returns 
 */
export const getFlashZonePriceAndLabel = (goodsInfo) => {
  const flashZonePromotion = goodsInfo?.flashZonePromotion || {}
  const { price } = flashZonePromotion

  const priceInfo = price?.amountWithSymbol ? price : {}

  const isInversion = getIsInversion(flashZonePromotion)

  return {
    priceInfo,
    color: 'origin',
    labelText: '',
    isInversion,
    suggestedSalePrice: flashZonePromotion.suggested_sale_price ?? {}
  }
}

/**
 * 获取售价价以及标签文字
 * @param {*} goodsInfo 预处理完商品数据
 * @param {*} language 商品项多语言
 * @returns 
 */
export const getSalePriceAndLabel = (goodsInfo, language, options) => {
  const { config } = options ?? {}
  const pretreatInfo = goodsInfo.pretreatInfo

  const price = goodsInfo.salePrice ?? {}

  // 限时直降活动，固定显示红色价格
  const showSellerLimitedLabel = pretreatInfo?.mixPromotionInfo?.some(item => +item?.typeId === 31 && +item?.promotion_logo_type === 3)

  const salePriceAmount = Number(price.amount)

  const retailPrice = goodsInfo?.retailPrice ?? {}
  const retailPriceAmount = Number(retailPrice.amount)

  const isInversion = getIsInversion(goodsInfo)

  let labelText = ''

  const noNeedAnyEstimatedPriceTag = getCardConfig(config, 'noNeedAnyEstimatedPriceTag', false)

  // 是否ab价
  const isABPrice = goodsInfo?.promotionInfo?.length && goodsInfo?.promotionInfo?.some(item => +item?.typeId === 32)
  if (isABPrice && !noNeedAnyEstimatedPriceTag) {
    labelText = language?.SHEIN_KEY_PWA_26364 || 'Estimated'
  }

  return {
    priceInfo: price?.amountWithSymbol ? price : {},
    color: (retailPriceAmount > salePriceAmount) || showSellerLimitedLabel ? 'origin' : '',
    labelText: labelText,
    isInversion,
    suggestedSalePrice: goodsInfo.suggestedSalePrice
  }
}
